<template>
  <v-form>
    <div class="d-flex align-md-end flex-column flex-md-row">
      <div
        v-for="(field, key) in form.fields"
        :key="key"
        class="d-flex flex-column mb-2"
      >
        <span class="mb-2 text-left">{{ field.title }}</span>
        <v-text-field
          v-model="form.payload[key]"
          :type="field.type"
          :label="field.label"
          :placeholder="field.placeholder || ''"
          :append-icon="field.icon || null"
          :rules="field.rules"
          :ref="field.label"
          :disabled="field.disabled"
          hide-details
          outlined
          :loading="field.useLoading && needLoadingIndicator"
          dense
          :class="field.class || ''"
        ></v-text-field>
      </div>
      <template v-for="button in form.buttons">
        <v-btn
          v-if="!button.checkOuter || showSBP"
          :key="button.label"
          :color="button.color || 'primary'"
          :class="button.class || ''"
          @click="button.action"
          :block="button.block"
          class="payment-btn ml-sm-2 mb-2"
          :loading="button.loading"
          :disabled="buttonsDisabled()"
        >
          {{ button.label }}
          <v-img
            v-if="button.logo"
            class="ml-2"
            :src="button.logo"
            height="24"
            width="24"
            max-height="24"
            max-width="24"
          />
        </v-btn>
      </template>
    </div>

    <div v-if="cardsAvailable.length > 1 && !activeBindingOuter" class="pt-5">
      <div class="w-full text-left pb-2">Способ оплаты</div>
      <v-btn
        v-model="cardsToggle"
        class="mt-0 pt-0 w-full no-transform text-left"
        @click="cardsToggle = !cardsToggle"
      >
        <template v-slot:default>
          <v-icon class="mr-2">mdi-credit-card-multiple</v-icon>
          <span class="button-span">{{
            (form.payload.selectedCard &&
              form.payload.selectedCard.displayLabel) ||
            ""
          }}</span>
        </template>
      </v-btn>
      <v-expand-transition>
        <v-container v-show="cardsToggle">
          <div
            v-for="(card, key) in cardsAvailable"
            :key="key"
            class="d-flex flex-column mb-2"
          >
            <v-btn
              plain
              class="no-transform text-left"
              @click="selectCard(card)"
            >
              <template v-slot:default>
                <v-icon class="mr-2">{{
                  card.icon || "mdi-credit-card"
                }}</v-icon>
                <span class="button-span">{{ card.displayLabel }}</span>
                <v-icon v-if="card.id > 0" @click.stop="deleteCard(card.id)"
                  >mdi-credit-card-off</v-icon
                >
              </template>
            </v-btn>
          </div>
        </v-container>
      </v-expand-transition>
    </div>

    <div v-if="recurrents.length > 0" class="pt-5">
      <div class="text-left mb-5 font-weight-bold">Подключены автоплатежи:</div>
      <table class="recurrent-table">
        <thead v-if="!isSmall">
          <tr>
            <th class="pt-2 pb-2">{{ dayText }}</th>
            <th class="pt-2 pb-2">{{ sumText }}</th>
            <th class="pt-2 pb-2">{{ endText }}</th>
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="recurrent in recurrents" :key="recurrent.id">
            <td :class="recurrentValueClass">
              <span v-if="isSmall">{{ dayText }}:</span>
              {{ recurrent.recurring_day }}
            </td>
            <td :class="recurrentValueClass">
              <span v-if="isSmall">{{ sumText }}:</span>
              {{ sumFormatted(recurrent.max_summ) }}
            </td>
            <td :class="recurrentValueClass">
              <span v-if="isSmall">{{ endText }}:</span>
              {{ dateFormatted(recurrent.max_date) }}
            </td>
            <td :class="recurrentValueClass" class="pt-0 pb-0 text-right">
              <v-btn
                class="ma-0 pa-0 no-transform"
                plain
                @click="deleteRecurrent(recurrent.id)"
              >
                Удалить
              </v-btn>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <template v-else>
      <div v-if="showRecurrent" class="pt-5">
        <v-switch
          v-model="form.recurrent"
          class="mt-0 pt-0"
          color="primary"
          hide-details
        >
          <template v-slot:label>
            <span class="text-caption">Подключить автоплатёж</span>
          </template>
        </v-switch>
        <v-expand-transition>
          <v-container v-show="form.recurrent">
            <div class="text-left pa-5" style="line-height: 1.5rem">
              <v-icon class="pr-1" small color="primary">
                mdi-information
              </v-icon>
              Внимание! Для подключения автоплатежа на странице ввода реквизитов
              карты установите флажок <b>Запомнить карту</b>
            </div>
            <div class="d-flex align-md-end flex-column flex-md-row">
              <div
                v-for="(field, key) in form.fieldsRecurrent"
                :key="key"
                class="d-flex flex-column mb-2"
              >
                <span class="mb-2 text-left">{{ field.title }}</span>
                <simple-date-picker
                  v-if="field.type === 'date'"
                  :date.sync="form.payload[key]"
                  :rules="field.rules"
                  :ref="field.label"
                  :disabled="field.disabled"
                />
                <simple-select
                  v-else-if="field.type === 'select'"
                  v-model="form.payload[key]"
                  :label="field.label"
                  :rules="field.rules"
                  :items="field.items"
                  dense
                  outlined
                  hide-details
                  :class="field.class || ''"
                  @item-changed="field.itemChangeHandler"
                />
                <v-text-field
                  v-else
                  v-model="form.payload[key]"
                  :type="field.type"
                  :label="field.label"
                  :placeholder="field.placeholder || ''"
                  :append-icon="field.icon || null"
                  :rules="field.rules"
                  :ref="field.label"
                  :disabled="field.disabled"
                  hide-details
                  outlined
                  :loading="field.useLoading && needLoadingIndicator"
                  dense
                  :class="field.class || ''"
                ></v-text-field>
              </div>
            </div>
          </v-container>
        </v-expand-transition>
      </div>
    </template>
  </v-form>
</template>

<script>
import PaymentForm from "@/forms/PaymentForm";
import { mapGetters } from "vuex";
import loadingIndicator from "../../shared/mixins/loadingIndicator";
import SimpleDatePicker from "@/components/SimpleDatePicker";
import SimpleSelect from "@/components/selects/SimpleSelect";
import { format, parseISO } from "date-fns";
import ApiService from "@/services/ApiService";
import PaymentsService from "@/services/PaymentsService";
import UserService from "@/services/UserService";

export default {
  name: "PaymentForm",
  components: { SimpleDatePicker, SimpleSelect },
  mixins: [loadingIndicator],
  data() {
    return {
      form: new PaymentForm(),
      loadingFlag: "get_charges",
      cardsToggle: false,
      cardsAvailable: [],
    };
  },
  props: {
    amount: {
      default: 0,
    }
  },
  computed: {
    ...mapGetters("user", ["activeBindingLs"]),
    ...mapGetters("account", ["total"]),
    ...mapGetters("account", ["recurrents"]),
    ...mapGetters("user", ["activeBindingId"]),
    ...mapGetters("user", ["activeBindingOuter"]),
    ...mapGetters("user", ["cards"]),
    isSmall() {
      return !!this.$vuetify.breakpoint.smAndDown;
    },
    recurrentValueClass() {
      //return "pt-0 pb-0 pr-5" + (this.isSmall ? " text-right" : "");
      return this.isSmall ? "recurrent-table-small" : "";
    },
    dayText() {
      return "День автоплатежа";
    },
    sumText() {
      return "Максимальная сумма";
    },
    endText() {
      return "Дата завершения";
    },
    showRecurrent() {
      return !this.activeBindingOuter && parseInt(this.form.payload.debt) <= 0 && parseInt(this.form.payload.gp) <= 0;
    },
    showSBP() {
      return !this.activeBindingOuter && parseInt(this.form.payload.debt) <= 0 && parseInt(this.form.payload.gp) <= 0;
    },
  },
  mounted() {
    this.formatCards();
    if (this.$route.query.amount) {
      this.form.payload.sum = this.$route.query.amount
    }
    this.form.payload.debt = this.$route.query.debt || 0
    this.form.payload.gp = this.$route.query.gp || 0
  },
  methods: {
    dateFormatted(date) {
      return format(parseISO(date), "dd.MM.yyyy");
    },
    sumFormatted(sum) {
      return (sum / 100).toFixed(2);
    },
    async deleteRecurrent(recurrentId) {
      try {
        await ApiService.deleteAccountRecurrent(
          this.activeBindingId,
          recurrentId
        );
        await PaymentsService.getRecurrents(this.activeBindingId);
      } catch (error) {
        console.log(error);
      }
    },
    formatCards() {
      this.cardsAvailable = Object.assign([], this.cards);
      this.cardsAvailable.unshift({
        id: null,
        displayLabel: "Использовать новую карту",
        icon: "mdi-credit-card-plus",
      });
      this.form.payload.selectedCard = this.cardsAvailable[0];
    },
    selectCard(card) {
      this.form.payload.selectedCard = card;
      this.cardsToggle = false;
    },
    async deleteCard(id) {
      await ApiService.deleteUserCard(id);
      await UserService.getUserCards();
    },
    buttonsDisabled() {
      return this.form.payload.sum <= 0
    },
  },
  watch: {
    activeBindingLs() {
      this.form.payload.lsNumber = this.activeBindingLs;
    },
    total() {
      this.form.payload.sum = this.total;
    },
    cards() {
      this.formatCards();
    },
  },
};
</script>

<style lang="scss">
.kop-input {
  width: 80px;

  .v-icon {
    font-size: 1em;
  }
}

.rub-input {
  .v-icon {
    font-size: 1.2em;
  }
}

.payment-btn.v-btn:not(.v-btn--round).v-size--default {
  height: 40px !important; // под высоту инпута
}

.sbp-text-color {
  color: #f5f1e8 !important;
}

.no-transform {
  text-transform: none !important;
}
.button-span {
  width: 100%;
}

/* таблица автоплатежей */
.recurrent-table {
  width: 100%;
  border-spacing: 0;
}
.recurrent-table th,
.recurrent-table td {
  border: 1px rgba(118, 118, 118, 0.3) solid;
}
.recurrent-table tr:first-child th:first-child {
  border-top-left-radius: 5px;
}
.recurrent-table tr:first-child th:last-child {
  border-top-right-radius: 5px;
}
.recurrent-table tr:last-child td:first-child {
  border-bottom-left-radius: 5px;
}
.recurrent-table tr:last-child td:last-child {
  border-bottom-right-radius: 5px;
}
.recurrent-table tr td {
  border-top: 0;
}
.recurrent-table tr th:not(:last-child),
.recurrent-table tr td:not(:last-child) {
  border-right: 0;
}

.recurrent-table-small {
  display: block;
  border: 0 !important;
  text-align: left;
}
</style>
